import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Collapse, Container, Grid, TextField, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";

export default function App(){

	const [ready, setReady]=useState(false);
	useEffect(()=>setReady(true), []);

	const [email, setEmail]=useState('');

	const [success, setSuccess]=useState(false);
	const [error, setError]=useState(false);
	const [errorMsg, setErrorMsg]=useState();

	function send(){
		const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		if(regex.test(email)){
			axios.post(
				'https://greenthumbs.app/submit-email.php',
				{
					email:email
				}
			).then((response)=>{
				console.log(response);
				if(response.data.status==="success"){
					setError(false);
					setSuccess(true);
				}
				else if(response.data.status==="error"){
					setError(true);
					setErrorMsg(response.data.message);
				}
			}).catch((error)=>{
				console.log(error);
			});
		}
		else{
			setSuccess(false);
			setErrorMsg('Please enter a valid email address');
			setError(true);
		}
	}

	return(
		<Box
			sx={{
				position:'relative',
			}}
		>
			<Box
				sx={{
					height:'100vh',
					overflow:'clip',
					mt:'0.75rem',
					opacity:'0.05',
				}}
			>
				<Grid container spacing={2}>
					{Array(156).fill(0).map((x, i)=>{
						return(
							<Grid item xs={2} md={1}
								sx={{
									textAlign:'center'
								}}
								key={i}
							>
								<FontAwesomeIcon 
									icon={faThumbsUp}
									style={{
										fontSize:'3rem',
										color:'#1A806A',
										opacity: ready? 1 : 0,
										transition:'opacity 0.5s',
										transitionDelay: i*0.01+'s'
									}}
								/>
							</Grid>
						);
					})}
				</Grid>
			</Box>
			<Box
				sx={{
					position:'absolute',
					top:0,
					left:0,
					width:'100%',
					height:'100%',
					display:'flex',
					flexDirection:'column',
					justifyContent:'center',
				}}
			>
				<Container maxWidth='sm'>
					<Box
						sx={{
							textAlign:'center',
							'& .MuiTypography-root':{
								fontFamily:'PT Serif',
								color:'#122926'
							}
						}}
					>
						<Typography variant='h1'
							sx={{
								fontSize:'3rem',
								fontWeight:600,
								opacity: ready? 1 : 0,
								transition:'opacity 0.5s'
							}}
						>
							GreenThumbs
						</Typography>
						<Typography
							sx={{
								fontSize:'10rem',
								opacity: ready? 1 : 0,
								transition:'opacity 0.5s',
								transitionDelay:'0.1s'
							}}
						>
							🍋
						</Typography>
						{success?
							<>
								<Typography
									sx={{
										m:'2rem 0',
										fontSize:'2rem',
										fontWeight:600,
										opacity: ready? 1 : 0,
										transition:'opacity 0.5s',
										transitionDelay:'0.3s'
									}}
								>
									Welcome to the community!
								</Typography>
								<Typography
									sx={{
										fontSize:'1.1rem',
										fontWeight:400,
										opacity: ready? 1 : 0,
										transition:'opacity 0.5s',
										transitionDelay:'0.4s'
									}}
								>
									We can't wait to share release updates up with you. Stay tuned 👀
								</Typography>
							</>
						:
							<>
								<Typography
									sx={{
										fontSize:'1.4rem',
										fontWeight:400,
										opacity: ready? 1 : 0,
										transition:'opacity 0.5s',
										transitionDelay:'0.2s',
										maxWidth:'350px',
										margin:'auto'
									}}
								>
									Browse and trade homegrown goods in your community
								</Typography>
								<Typography
									sx={{
										m:'2rem 0',
										fontSize:'2rem',
										fontWeight:600,
										opacity: ready? 1 : 0,
										transition:'opacity 0.5s',
										transitionDelay:'0.3s'
									}}
								>
									Coming soon!
								</Typography>
								<Typography
									sx={{
										fontSize:'1.1rem',
										fontWeight:400,
										opacity: ready? 1 : 0,
										transition:'opacity 0.5s',
										transitionDelay:'0.4s'
									}}
								>
									Sign up to our newsletter to be the first to hear about updates
								</Typography>
								<Box
									sx={{
										mt:'2rem',
										width:'100%',
										opacity: ready? 1 : 0,
										transition:'opacity 0.5s',
										transitionDelay:'0.5s',
										display:'flex'
									}}
								>
									<TextField
										fullWidth
										type='email'
										placeholder='Enter email address'
										value={email}
										onChange={(e)=>setEmail(e.target.value)}
										error={error}
										onKeyDown={(e)=>{
											if(e.key==='Enter'){
												send();
											}
										}}
										sx={{
											background:'#fff',
											borderRadius:'0.5rem 0 0 0.5rem',
											'& .MuiInputBase-root':{
												borderRadius:'0.5rem 0 0 0.5rem',
												fontFamily:'PT Serif',
											}
										}}
									/>
									<Button
										onClick={send}
										sx={{
											width:'100px',
											background:'#25534B',
											color:'#fff',
											textTransform:'none',
											fontFamily:'PT Serif',
											borderRadius:'0 0.5rem 0.5rem 0',
											'&:hover':{
												background:'#122926'
											}
										}}
									>
										Sign up
									</Button>
								</Box>
								<Collapse in={error}>
									<Typography
										sx={{
											mt:'1rem',
											color:'red !important',
											fontSize:'1rem',
											fontWeight:600,
										}}
									>
										{errorMsg}
									</Typography>
								</Collapse>
							</>
						}
					</Box>
				</Container>
			</Box>
		</Box>
	);
}